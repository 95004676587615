import React, { useState } from "react";
import { Form, Input, Button, Col, Row, Result } from "antd";
import RightArrow from "../icons/RightArrow";

export const ContactUsForm = ({ style }: any) => {
  const [form] = Form.useForm();
  const formName = "Contact Us";
  const [isContactLoading, setIsContactLoading] = useState(false);
  const { TextArea } = Input;
  const [isSent, setIsSent] = useState(false);

  const encode = (data: any) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + `=` + encodeURIComponent(data[key]))
      .join(`&`)
  }

  const handleSubmit = (values: any) => {
    if (values[`bot-field`] === undefined) {
      delete values[`bot-field`]
    }

    fetch(`/`, {
      method: `POST`,
      headers: { 'Content-Type': `application/x-www-form-urlencoded` },
      body: encode({
        'form-name': formName,
        ...values,
      }),
    })
      .then(r => {
        if (r.ok) setIsSent(true);
      })
      .finally(() => {
        setIsContactLoading(false);
      });
  }

  return (
    <div className="contact-form landing-container" style={style}>
      <div className="contactForm_cont">
        <h2 className="contact-form__title">CONTACT US</h2>
        {isSent ? (
          <Result
            status="success"
            icon={
              <svg
                width="100"
                height="100"
                viewBox="0 0 100 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M88 50C88 70.9868 70.9868 88 50 88C29.0132 88 12 70.9868 12 50C12 29.0132 29.0132 12 50 12C70.9868 12 88 29.0132 88 50Z"
                  stroke="#BDA06D"
                  strokeWidth="4"
                />
                <path
                  d="M43.7113 60.4158C43.6497 60.4158 43.5916 60.4124 43.5301 60.4021C43.1063 60.344 42.7371 60.0945 42.5252 59.7254L37.0804 50.1995C36.7044 49.5432 36.9334 48.7092 37.5897 48.3333C38.2459 47.9573 39.0799 48.1863 39.4559 48.8425L44.0086 56.8132L60.7908 39.9797C61.324 39.4465 62.1888 39.4431 62.7254 39.9763C63.2586 40.5095 63.262 41.3743 62.7288 41.9109L44.7435 59.9509L44.6785 60.0159C44.4188 60.2756 44.0701 60.4158 43.7113 60.4158Z"
                  fill="#BDA06D"
                />
                <mask
                  id="mask0"
                  mask-type="alpha"
                  maskUnits="userSpaceOnUse"
                  x="36"
                  y="39"
                  width="28"
                  height="22"
                >
                  <path
                    d="M43.7113 60.4158C43.6497 60.4158 43.5916 60.4124 43.5301 60.4021C43.1063 60.344 42.7371 60.0945 42.5252 59.7254L37.0804 50.1995C36.7044 49.5432 36.9334 48.7092 37.5897 48.3333C38.2459 47.9573 39.0799 48.1863 39.4559 48.8425L44.0086 56.8132L60.7908 39.9797C61.324 39.4465 62.1888 39.4431 62.7254 39.9763C63.2586 40.5095 63.262 41.3743 62.7288 41.9109L44.7435 59.9509L44.6785 60.0159C44.4188 60.2756 44.0701 60.4158 43.7113 60.4158Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0)"></g>
              </svg>
            }
            subTitle={
              <div style={{ color: "#fff" }}>
                Your message has been sent successfully. We will get back to you
                shortly{" "}
              </div>
            }
          />
        ) : (
          <>
            <h3 className="contact-form__subtitle">
              Please fill out the form below
            </h3>
            <p className="contact-form__info">
              we will get back to you shortly{" "}
            </p>
            <form
              name={formName}
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              hidden
            >
              <input type="text" name="name" />
              <input type="email" name="email" />
              <textarea name="message"></textarea>
            </form>
            <Form
              form={form}
              method="post"
              name="cf"
              onFinish={handleSubmit}
            >
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your name!",
                      },
                    ]}
                  >
                    <Input placeholder="Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                    ]}
                  >
                    <Input type="email" placeholder="Email" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="message"
                rules={[
                  {
                    required: true,
                    message: "Please input your message!",
                  },
                ]}
              >
                <TextArea rows={2} placeholder="Message" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn btn-primary contact-form__btn"
                  loading={isContactLoading}
                >
                  Send Message <RightArrow />
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </div>
    </div>
  );
};
