import React from "react";

function RightArrow({ color = "#020202" }: any) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 11L17.17 11L13.59 7.41L15 6L21 12L15 18L13.59 16.59L17.17 13L3 13V11Z"
        fill={color}
      />
    </svg>
  );
}

export default RightArrow;
