import React from "react";
import { Row, Col } from "antd";

export const TextSection = ({ title, children, style, className }: any) => {
  return (
    <div className={`TextSection ${className}`} style={style}>
      <Row align="top" gutter={40}>
        <Col sm={24} md={10} style={{ marginBottom: 20 }}>
          <div className="TextSection_title cl">
            <div className="TextSection_titleText">{title}</div>
            <div className="line"></div>
          </div>
        </Col>
        <Col sm={24} md={14}>
          <div className="TextSection_content cr">{children}</div>
        </Col>
      </Row>
    </div>
  );
};
