import React from "react";
import { Col, Row } from "antd";
import { graphql } from "gatsby";
import { IGatsbyImageData, StaticImage } from "gatsby-plugin-image"

import "../styles/about-us.scss";

import { SEO, Layout, Footer } from "../components";
import { HomeHeader } from "../components/Headers/HomeHeader";
import { TextSection } from "../components/common/TextSection";
import { ContactUsForm } from "../components/common/ContactUsForm";

import StaffSingle from "../components/Staff/StaffSinge";
import BrandsAffiliations from "../components/BrandsAffiliations/BrandsAffiliations";

type Brand = {
  url: string;
  name: string;
  logo: {
    gatsbyImageData: IGatsbyImageData;
    description: string;
  };
};

type MemberProps = {
  name: string;
  title: string;
  bio: {
    childMarkdownRemark: {
      html: string;
    };
  };
  photo: {
    description: string;
    gatsbyImageData: IGatsbyImageData;
  };
};

type OfficeProps = {
  teamMembers: MemberProps[];
};

type AboutUsProps = {
  data: {
    contentfulPage: {
      title: string,
      seoDescription: string,
      socialShareThumbnail: {
        file: {
          url: string
        }
      }
    }
    brands: {
      nodes: Brand[];
    };
    boardMembers: {
      nodes: MemberProps[];
    };
    singaporeOffice: {
      nodes: OfficeProps[];
    };
    switzerlandOffice: {
      nodes: OfficeProps[];
    };
    hongkongOffice: {
      nodes: OfficeProps[];
    };
  };
};

const AboutUs = ({ data }: AboutUsProps) => {
  const brands = data.brands.nodes;
  const { title, seoDescription, socialShareThumbnail } = data.contentfulPage;
  const singaporeTeam = (data.singaporeOffice.nodes[0] && data.singaporeOffice.nodes[0].teamMembers) || [];
  const switzerlandTeam = (data.switzerlandOffice.nodes[0] && data.switzerlandOffice.nodes[0].teamMembers) || [];
  const hongkongTeam = (data.hongkongOffice.nodes[0] && data.hongkongOffice.nodes[0].teamMembers) || [];
  const boardMembers = data.boardMembers.nodes || [];

  return (
    <Layout
      header={<HomeHeader style={{ marginBottom: 0 }} />}
      footer={<Footer />}
    >
      <SEO
        title={title}
        desc={seoDescription}
        image={socialShareThumbnail.file.url}
      />

      <div className="aboutUs">
        <div
          className="pageTitle header-home"
          style={{
            backgroundColor: "#F1F2EA",
          }}
        >
          <h1 className="pageTitle_name text-uppercase">WHO WE ARE</h1>
          <Row className="pageTitle_elm2" gutter={5}>
            <Col>HOME</Col>
            <Col>
              <svg
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.49792 -0.000114648L0 3.4978L3.53553 7.03334L7.03345 3.53542L3.49792 -0.000114648Z"
                  fill="#BDA06D"
                />
              </svg>
            </Col>
            <Col>
              <span className="pageTitle_e2">WHO WE ARE</span>
            </Col>
          </Row>
        </div>

        <div className="aboutUs__SectionTow">
          <TextSection title="GLOBAL PRECIOUS METALS SISTER COMPANY">
            <div className="aboutUs__p">
              GPM’s parent company (Nimoi Holdings Pte. Ltd.) is a family
              holding company whose roots go back to 1959 when its predecessor,
              Woodside Holdings, was established as family office in the Colony
              of Singapore by the Directors’ grandfather, Sir Ewen Fergusson.
              <br />
              <br />
              <span style={{ fontWeight: "bold" }}>
                GPM’s sister company, Woodside Holdings Investment Management
                Pte Ltd, is registered with the Monetary Authority of Singapore
                to operate as a Fund Management Company.
              </span>
            </div>
          </TextSection>
        </div>

        <Row>
          <Col xs={24} md={12} lg={12} xl={12}>
            <StaticImage
              src={"../images/blackBoat.png"}
              alt="A boat in a river"
              placeholder="blurred"
              layout="fullWidth"
              formats={["auto", "webp"]}
            />
          </Col>
          <Col xs={24} md={12} lg={12} xl={12}>
            <StaticImage
              src={"../images/blackHouse.png"}
              alt="A house in a hill"
              placeholder="blurred"
              layout="fullWidth"
              formats={["auto", "webp"]}
            />
          </Col>
        </Row>
        <div className="aboutUs__container">
          <div className="aboutUs__thirdSectionTitle"> Board of Directors</div>{" "}
          <div
            className="aboutUs__lineBorder"
            style={{ margin: "6px 0 43px 0" }}
          />
        </div>

        <div>
          <Row gutter={0}>
            {boardMembers.map((staff, i) => {
              return (
                <Col
                  xs={24}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ marginBottom: 40 }}
                  key={i}
                >
                  <StaffSingle
                    name={staff.name}
                    title={staff.title}
                    bio={staff.bio.childMarkdownRemark.html}
                    photo={staff.photo}
                    right={i % 2 === 1}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
        <div className="aboutUs__container">
          <div className="aboutUs__thirdSectionTitle"> Singapore</div>{" "}
          <div
            className="aboutUs__lineBorder"
            style={{ margin: "6px 0 43px 0" }}
          />
        </div>
        <Row gutter={0}>
          {singaporeTeam.map((staff, i) => {
            return (
              <Col
                xs={24}
                md={12}
                lg={12}
                xl={12}
                style={{ marginBottom: 40 }}
                key={i}
              >
                <StaffSingle
                  name={staff.name}
                  title={staff.title}
                  bio={staff.bio.childMarkdownRemark.html}
                  photo={staff.photo}
                  right={i % 2 === 1}
                />
              </Col>
            );
          })}
        </Row>
        <div className="aboutUs__container">
          <div className="aboutUs__thirdSectionTitle"> Switzerland</div>{" "}
          <div
            className="aboutUs__lineBorder"
            style={{ margin: "6px 0 43px 0" }}
          />
        </div>
        <Row gutter={0} style={{ marginBottom: 112 }}>
          {switzerlandTeam.map((staff, i) => {
            return (
              <Col
                xs={24}
                md={12}
                lg={12}
                xl={12}
                style={{ marginBottom: 40 }}
                key={staff.name}
              >
                <StaffSingle
                  name={staff.name}
                  title={staff.title}
                  bio={staff.bio.childMarkdownRemark.html}
                  photo={staff.photo}
                  right={i % 2 === 1}
                />
              </Col>
            );
          })}
        </Row>
        {hongkongTeam.length > 0 && (
        <div className="aboutUs__container">
          <div className="aboutUs__thirdSectionTitle"> Hong Kong</div>{" "}
          <div
            className="aboutUs__lineBorder"
            style={{ margin: "6px 0 43px 0" }}
          />
        </div>
        )}
        <Row gutter={0} style={{ marginBottom: 112 }}>
          {hongkongTeam.map((staff, i) => {
            return (
              <Col
                xs={24}
                md={12}
                lg={12}
                xl={12}
                style={{ marginBottom: 40 }}
                key={staff.name}
              >
                <StaffSingle
                  name={staff.name}
                  title={staff.title}
                  bio={staff.bio.childMarkdownRemark.html}
                  photo={staff.photo}
                  right={i % 2 === 1}
                />
              </Col>
            );
          })}
        </Row>
      </div>
      <BrandsAffiliations bgColor="#020202" brands={brands} />
      <div className="aboutUs__SectionFive">
        {" "}
        <ContactUsForm />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query AboutUsQuery {
    contentfulPage(slug: {eq: "about-us"}) {
      title
      seoDescription
      socialShareThumbnail {
        file {
          url
        }
      }
    }
    brands: allContentfulAffiliationBrand {
      nodes {
        url
        name
        logo {
          gatsbyImageData(layout: FIXED, placeholder: BLURRED, formats: [AUTO, WEBP])
          description
        }
      }
    }
    switzerlandOffice: allContentfulGpmOffice(
      filter: { name: { eq: "Switzerland Office" } }
    ) {
      nodes {
        name
        teamMembers {
          name
          title
          bio {
            childMarkdownRemark {
              html
            }
          }
          photo {
            description
            gatsbyImageData(layout: FIXED, placeholder: BLURRED, formats: [AUTO, WEBP])
            title
          }
        }
      }
    }
    singaporeOffice: allContentfulGpmOffice(
      filter: { name: { eq: "Singapore Office" } }
    ) {
      nodes {
        name
        teamMembers {
          name
          title
          bio {
            childMarkdownRemark {
              html
            }
          }
          photo {
            description
            gatsbyImageData(layout: FIXED, placeholder: BLURRED, formats: [AUTO, WEBP])
            title
          }
        }
      }
    }
    hongkongOffice: allContentfulGpmOffice(
      filter: { name: { eq: "Hong Kong Office" } }
    ) {
      nodes {
        name
        teamMembers {
          name
          title
          bio {
            childMarkdownRemark {
              html
            }
          }
          photo {
            description
            gatsbyImageData(layout: FIXED, placeholder: BLURRED, formats: [AUTO, WEBP])
            title
          }
        }
      }
    }
    boardMembers: allContentfulBoardMember(sort: { fields: createdAt }) {
      nodes {
        name
        title
        bio {
          childMarkdownRemark {
            html
          }
        }
        photo {
          description
          gatsbyImageData(layout: FIXED, placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
    }
  }
`;

export default AboutUs;