import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Affix } from "antd";
import gsap from "gsap";
import scrollTo from "gatsby-plugin-smoothscroll";
import { useWindowSize } from "../../utils/hooks";
import "../../styles/home-navigation.scss";
import RightArrow from "../icons/RightArrow";
// @ts-expect-error
import li from "../../images/li.svg";
import { Logo } from "../icons/Logo";

const BREAK_POINT = 1070;

export const HomeHeader = ({ style }) => {
  const [eligibBtnVisible, setEligibBtnVisible] = useState(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const windowWidthSize = useWindowSize();

  useEffect(() => {
    if (windowWidthSize > BREAK_POINT) setMobileMenuVisible(false);
  }, [windowWidthSize]);
  useEffect(() => {
    const h = e => {
      e.preventDefault();
      e.stopPropagation();
    };
    if (mobileMenuVisible) {
      window.addEventListener("wheel", h, { passive: false });
      window.addEventListener("scroll", h, { passive: false });
      window.addEventListener("touchmove", h, { passive: false });
      return () => {
        window.removeEventListener("wheel", h);
        window.removeEventListener("scroll", h);
        window.removeEventListener("touchmove", h);
      };
    }
    return () => { };
  }, [mobileMenuVisible]);

  useEffect(() => {
    if (mobileMenuVisible) gsap.set(".mobileMenu", { display: "initial" });
    gsap.to(".mobileMenu", {
      duration: 0.5,
      opacity: mobileMenuVisible ? 1 : 0,
      onComplete: () => {
        if (!mobileMenuVisible) gsap.set(".mobileMenu", { display: "none" });
      },
    });
  }, [mobileMenuVisible]);

  useEffect(() => {
    const onScroll = () => {
      const elm = document.getElementById("check-eligib");
      const bbox = elm.getBoundingClientRect();
      const cond = bbox.top < 50;
      setEligibBtnVisible(cond);
      gsap.to("#eligib-cont", {
        duration: 0.2,
        width: cond ? 233 : 0,
        onComplete: () => { },
      });
    };
    if (window.location.pathname === "/") {
      onScroll();
      document.addEventListener("scroll", onScroll);
      return () => {
        document.removeEventListener("scroll", onScroll);
      };
    }
    setEligibBtnVisible(true);

    return () => { };
  }, []);

  return (
    <div>
      <Affix offsetTop={0}>
        <header
          style={{
            background: `#000`,
            marginBottom: `40px`,
            height: "90px",
            display: "flex",
            alignItems: "center",
            ...style,
          }}
        >
          <div className="signup_header header-home">
            <Logo />

            <div className="home-navigation">
              <Link
                to="/about-us/"
                className="home-navigation__link"
                activeClassName="home-navigation__link--active"
              >
                Who We Are
              </Link>
              <Link
                to="/gold-guide/"
                className="home-navigation__link"
                activeClassName="home-navigation__link--active"
              >
                Gold Guide
              </Link>
              <Link
                to="/contact-us/"
                className="home-navigation__link"
                activeClassName="home-navigation__link--active"
              >
                GET IN TOUCH
              </Link>
              <Link
                to="/blog/"
                className="home-navigation__link"
                activeClassName="home-navigation__link--active"
              >
                BLOG
              </Link>
              <div id="eligib-cont">
                {eligibBtnVisible ? (
                  <Link
                    to="/register/"
                    className="home-navigation__link btn btn-primary hero__content-btn"
                    style={{ margin: "0 auto" }}
                  >
                    CHECK YOUR ELIGIBILITY
                  </Link>
                ) : null}
              </div>
            </div>
            <div
              className="burger"
              onClick={() => {
                setMobileMenuVisible(o => !o);
              }}
              style={{ cursor: "pointer" }}
            >
              {mobileMenuVisible ? (
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                    fill="#F1F2EA"
                  />
                </svg>
              ) : (
                <svg
                  width="24"
                  height="16"
                  viewBox="0 0 24 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 16H24V13.3333H0V16ZM0 9.33336H24V6.66669H0V9.33336ZM0 0V2.66667H24V0H0Z"
                    fill="#F1F2EA"
                  />
                </svg>
              )}
            </div>
          </div>
        </header>
      </Affix>
      <div className="mobileMenu">
        <div className="mobileMenu__links">
          <Link
            to="/about-us/"
            className="home-navigation__link"
            activeClassName="home-navigation__link--active"
          >
            Who We Are
          </Link>
          <Link
            to="/gold-guide/"
            className="home-navigation__link"
            activeClassName="home-navigation__link--active"
          >
            Gold Guide
          </Link>
          <Link
            to="/contact-us/"
            className="home-navigation__link"
            activeClassName="home-navigation__link--active"
          >
            GET IN TOUCH
          </Link>
          <Link
            to="/blog/"
            className="home-navigation__link"
            activeClassName="home-navigation__link--active"
          >
            BLOG
          </Link>
        </div>
        <div className="mobileMenu__btns">
          <Link to="/register/" className="btn btn-primary hero__content-btn">
            CHECK YOUR ELIGIBILITY <RightArrow />
          </Link>
          <div
            className="btn btn-primary hero__content-btn contact-us-btn"
            // className={}
            onClick={() => {
              setMobileMenuVisible(false);
              scrollTo("#contact-form");
            }}
          >
            CONTACT US <RightArrow />
          </div>
          <a
            href="https://www.linkedin.com/company/global-precious-metals/"
            target="_blank"
            rel="noreferrer"
          >
            <img className="linkedInBtn" src={li} alt="LinkedIn" />
          </a>
        </div>
      </div>
    </div>
  );
};

HomeHeader.propTypes = {
  style: PropTypes.object,
};

HomeHeader.defaultProps = {
  siteTitle: ``,
};
