import React from "react";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";

import "./Staff.scss";

type Photo = {
  gatsbyImageData: IGatsbyImageData;
  description: string;
};

type StaffSingleProps = {
  name: string;
  title: string;
  bio: string;
  photo: Photo;
  right: boolean;
};

const StaffSingle = ({ name, title, bio, photo, right }: StaffSingleProps) => {
  const image = getImage(photo.gatsbyImageData);

  return (
    <div className="staff">
      <div
        className={`staff staff__background ${right ? "staff_right" : "staff_left"
          }`}
      >
        <GatsbyImage image={image} alt={photo.description} />
      </div>
      <div className="staff__container">
        <h4 className="staff__name">{name}</h4>
        <h5 className="staff__title">{title}</h5>
        <div
          className="staff__bio"
          dangerouslySetInnerHTML={{
            __html: bio,
          }}
        />
      </div>
    </div>
  );
};

StaffSingle.defaultProps = {
  name: "John Doe",
  title: "CEO",
  bio:
    "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Deleniti, suscipit, minima debitis sint maxime dolorem placeat dicta autem nam, sunt ducimus nisi. Asperiores, expedita dolor aperiam at atque blanditiis tempore.",
  right: false,
  photo: {
    gatsbyImageData: {},
    description: "Lorem ipsum",
  },
};

export default StaffSingle;
