import React from "react";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { Row, Col } from "antd";

import "./BrandsAffiliations.scss";

type Brand = {
  url: string;
  name: string;
  logo: {
    gatsbyImageData: IGatsbyImageData;
    description: string;
  };
};

type BrandsAffiliationsProps = {
  bgColor: string;
  brands: Brand[];
};

const BrandsAffiliations = ({ bgColor, brands }: BrandsAffiliationsProps) => {
  return (
    <div className="brand-affiliations" style={{ backgroundColor: bgColor }}>
      <div className="container">
        <div className="brand-affiliations__title"> Our Affiliations</div>

        <Row className="brand-affiliations__grid">
          {brands.map(brand => {
            const logo = getImage(brand.logo.gatsbyImageData);

            return (
              <Col
                xs={24}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="brand-affiliations__cell"
                key={brand.name}
              >
                <a href={brand.url} target="_blank" rel="noreferrer">
                  <GatsbyImage image={logo} alt={brand.logo.description} />
                </a>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};

BrandsAffiliations.defaultProps = {
  bgColor: "#020202",
  brands: [],
};

export default BrandsAffiliations;
